import React from "react";
import { graphql, PageProps } from "gatsby";

import * as styles from "./Guides.module.scss";

import { Hero, SideNav } from "components/guides";
import { Related } from "components/design-system";
import SEO from "components/SEO";
import pagesMeta from "data/pagesMeta";
import { extractCategories } from "helpers/utils/generics";
import { Category, Guide } from "types";

const Guides: React.FC<PageProps> = ({ data }) => {
  const {
    guides: { edges: guides },
  } = data as any;

  const categories = extractCategories(
    guides[0].node.data,
    "/guides/category"
  ) as Category[];

  const popularGuides = guides[0].node.data.filter(
    (guide: any) => guide.attributes.popular
  ) as Guide[];

  const relatedItems = popularGuides.map(
    ({
      attributes: {
        title,
        featuredImage: {
          data: {
            attributes: { localFile: image, ext, url },
          },
        },
        slug,
        description,
        category: { data: category },
      },
    }) =>
      Object.assign(
        {
          title,
          link: `/guides/${category.attributes.slug}/${slug}`,
          excerpt: description,
        },
        ext === ".gif" ? { featuredImage: url } : { image }
      )
  );

  return (
    <>
      <SEO {...pagesMeta.guides} />
      <div className={styles.Guides}>
        <SideNav
          type="Index"
          title="What do you need help with?"
          categories={categories}
          pathPath="/guides/category"
        />
        <div className={styles.Guides_main}>
          <Hero />
          <div className={styles.Guides_related}>
            <Related
              align="left"
              overflow={false}
              title="Popular topics on our business guide"
              items={relatedItems}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const pageQuery = graphql`
  query GuidesIndexQuery {
    guides: allStrapiGuide {
      edges {
        node {
          data {
            id
            attributes {
              category {
                data {
                  id
                  attributes {
                    name
                    slug
                  }
                }
              }
              content
              date
              description
              featuredImage {
                data {
                  attributes {
                    ext
                    url
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
              popular
              slug
              title
            }
          }
        }
      }
    }
  }
`;

export default Guides;
